<template>
  <div style="position: relative">
    <b-progress
      :value="progressMax"
      :max="progressMax"
      :height="progressH"
      show-value
    >
      <!-- <span>{{ progressMax }}</span> -->
      <b-progress-bar
        v-for="(i, k) in progressNumber"
        :key="k"
        :value="i.value"
        :variant="i.variant"
      ></b-progress-bar>
    </b-progress>
    <div class="progress_text" v-if="0">
      <!-- <p v-for="(i, k) in progressNumber" :key="k">
        {{ i.value }} <span>/</span>
      </p> -->
      <p>{{ purchaseQty }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MulticolorProgressBar',
  components: {},
  data() {
    return {};
  },
  props: {
    progressNumber: {
      type: Array
    },
    progressMax: {
      type: Number
    },
    progressH: {
      type: String
    },
    purchaseQty: {
      type: Number
    }
  },
  methods: {},
  computed: {},
  mounted() {}
};
</script>
<style scoped>
.progress_text p {
  margin-bottom: 0;
}
.progress_text {
  display: flex;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  justify-content: center;
  align-items: center;
}
.progress_text p:last-child span {
  display: none;
}
.progress .progress-bar.bg-secondary {
  text-indent: -9999px;
}
</style>
