<template>
  <div>
    <b-row class="search-box" :class="{ 'n-p-b': noFooter }">
      <b-col
        v-for="(item, index) in searchConfiguration"
        :key="index"
        :md="item.md || 6"
        class="my-1"
      >
        <b-form-group
          :label="item.text"
          :class="item.class"
          :id="item.id"
          :label-for="'search-' + index"
          class="search-flex"
          label-cols-sm="4"
          label-cols-lg="3"
          v-if="
            item.type != 'datepicker_from_to' &&
              item.type != 'datepicker' &&
              item.show != false
          "
        >
          <!-- 复选框 -->
          <template v-if="item.type == 'checked-all'">
            <!-- 全选 -->
            <b-form-checkbox
              v-model="item.allSelected"
              :id="'all-selected' + index"
              :name="'all-selected' + index"
              :indeterminate="indeterminate"
              class="all-selected"
              :class="item.class"
              aria-describedby="flavours"
              aria-controls="flavours"
              @change="toggleAll($event, item, searchObj, index)"
            >
              {{ item.allSelected ? 'All' : 'All' }}
            </b-form-checkbox>
            <!-- 子项 -->
            <!-- :options="item.options" -->
            <b-form-checkbox-group
              v-model="searchObj[index]"
              :id="'selected' + index"
              :name="'checkbox-' + index"
              :class="item.options.class"
              text-field="label"
              aria-label="Individual flavours"
              @input="ifAll(item, searchObj, index)"
            >
              <b-form-checkbox
                :class="i.class"
                :value="i.value"
                v-for="(i, x) in item.options"
                :key="x"
                >{{ i.label }}</b-form-checkbox
              >
            </b-form-checkbox-group></template
          >

          <template v-if="item.type == 'checked'">
            <!-- 子项 -->
            <b-form-checkbox-group
              v-model="searchObj[index]"
              :id="'selected' + index"
              :name="'checkbox-' + index"
              :class="item.options.class"
              text-field="label"
              aria-label="Individual flavours"
            >
              <b-form-checkbox
                :class="i.class"
                :value="i.value"
                v-for="(i, x) in item.options"
                :key="x"
                >{{ i.label }}</b-form-checkbox
              >
            </b-form-checkbox-group></template
          >

          <!-- 下拉框 -->
          <template v-else-if="item.type == 'select'">
            <SelectLoading
              v-if="item.options == 0 && !item.hideLoading"
              :size="0.7"
            ></SelectLoading>
            <sv-select
              v-model="searchObj[index]"
              v-if="item.onSearch"
              :disabled="item.options == 0 && !item.hideLoading"
              :placeholder="item.placeholder || $t('page.please_choose')"
              :options="item.options"
              :reduce="options => options.value"
              :multiple="item.multiple"
              :selectable="options => !options.disabled"
              :filter="ajaxFilter"
              @input="item.input ? item.input($event) : null"
              @search="item.onSearch"
              label="label"
            >
              <template slot="option" slot-scope="option" v-if="option.index">
                <div class="d-center" :class="'level_' + option.index">
                  <span v-if="option.index == 2">--</span>
                  {{ option.label }}
                </div>
              </template>
            </sv-select>

            <sv-select
              v-if="item.onSearch == null"
              v-model="searchObj[index]"
              :disabled="item.options == 0"
              :placeholder="item.placeholder || $t('page.please_choose')"
              :options="item.options"
              :reduce="options => options.value"
              :multiple="item.multiple"
              :selectable="options => !options.disabled"
              @input="item.input ? item.input($event) : null"
              label="label"
            >
              <template slot="option" slot-scope="option" v-if="option.index">
                <div class="d-center" :class="'level_' + option.index">
                  <span v-if="option.index == 2">--</span>
                  {{ option.label }}
                </div>
              </template>
            </sv-select>
          </template>

          <!-- input -->
          <template v-else-if="item.type == 'input'">
            <b-input-group>
              <template v-slot:append>
                <b-input-group-text v-if="item.icon">
                  <b-icon :icon="item.icon"></b-icon>
                </b-input-group-text>
              </template>

              <b-form-input
                v-model="searchObj[index]"
                :type="item.input_type || 'text'"
                :placeholder="
                  item.placeholder || $t('page.please_choose_input')
                "
                @keyup.enter="filter"
              ></b-form-input>
            </b-input-group>
          </template>
          <!-- 单选框 -->
          <template v-else-if="item.type == 'radio'">
            <b-form-radio-group
              v-model="searchObj[index]"
              :id="item.id"
              :options="item.options"
              text-field="label"
              :name="'radio-' + index"
            ></b-form-radio-group>
          </template>
        </b-form-group>
        <!-- 日期 -->
        <template
          v-if="item.type == 'datepicker_from_to' || item.type == 'datepicker'"
        >
          <Datepicker
            :configuration="item"
            :datepickerKye="index"
            :datepickerObj="searchObj"
          ></Datepicker>
        </template>
      </b-col>
      <b-col md="12" class="my-1" v-if="!noFooter">
        <div class="footer">
          <b-button
            type="submit"
            variant="primary"
            class="filter mr-2"
            @click="filter"
            size="sm"
            >Filter <b-icon icon="filter" aria-hidden="true"></b-icon
          ></b-button>
          <b-button
            type="reset"
            variant="secondary"
            class="reset mr-2"
            @click="removeData"
            size="sm"
            >Reset</b-button
          >
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// import { mapGetters } from 'vuex';
import Datepicker from '@/components/Datepicker/Index.vue';
import SelectLoading from '@/components/Loading/Index';

export default {
  name: 'Advanced',
  computed: {},
  data() {
    return {
      // flavours: ['orange'],
      indeterminate: false
    };
  },
  props: {
    searchConfiguration: Object,
    searchObj: Object,
    tableId: String,
    noFooter: Boolean
  },
  methods: {
    toggleAll(checked, item, obj, key) {
      let checkedArr = [];
      item.options.forEach(element => {
        checkedArr.push(element.value);
      });
      obj[key] = checked ? checkedArr : [];
    },
    ajaxFilter(options) {
      return options;
    },
    ifAll(item, obj, key) {
      if (obj[key]?.length == item.options?.length) {
        item.allSelected = true;
      } else {
        item.allSelected = false;
      }
    },
    removeData() {
      Object.keys(this.searchConfiguration).forEach(k => {
        if (this.searchConfiguration[k].resetVal != null) {
          this.searchObj[k] = this.searchConfiguration[k].resetVal;
        } else {
          if (this.searchConfiguration[k].type != 'datepicker_from_to') {
            if (
              this.searchConfiguration[k].type != 'checked-all' &&
              this.searchConfiguration[k].type != 'checked'
            ) {
              this.searchObj[k] = null;
            } else {
              this.searchObj[k] = [];
            }
          } else {
            var from = k + '_from';
            var to = k + '_to';
            this.searchObj[from] = null;
            this.searchObj[to] = null;
            if (this.searchConfiguration[k].from.resetVal != null) {
              this.searchObj[from] = this.searchConfiguration[k].from.resetVal;
            }
            if (this.searchConfiguration[k].to.resetVal != null) {
              this.searchObj[to] = this.searchConfiguration[k].to.resetVal;
            }
          }
        }
      });

      // console.log(this.searchObj.sort);
      if (
        this.searchObj &&
        this.searchObj.sort &&
        Object.keys(this.searchObj.sort).length > 0
      ) {
        this.searchObj.sort = null;
      } else {
        this.$root.$emit('bv::refresh::table', this.tableId);
      }
      this.CACHE.renewCache();
    },
    filter() {
      this.$root.$emit('bv::refresh::table', this.tableId);
    }
  },

  components: {
    Datepicker,
    SelectLoading
  },
  mounted() {},
  watch: {}
};
</script>
<style scoped></style>
