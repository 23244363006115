<template>
  <div>
    <b-button
      class=" comment"
      :class="'grade-' + grade"
      :id="`popover-${Index}`"
      @click="show"
    >
      <b-icon-card-text font-scale="1.45"></b-icon-card-text>
    </b-button>
    <b-popover
      :target="`popover-${Index}`"
      triggers="hover"
      placement="left"
      show.sync="popoverShow"
    >
      <div
        v-for="(item, index) in commentList"
        :key="index"
        class="leave-comments-box"
      >
        <!-- {{ item }} -->
        <p class="flex">
          <b>{{ item.name }}</b>
          <b>{{ item.created_at }}</b>
        </p>
        <p :class="'importance-' + item.importance">{{ item.comment }}</p>
      </div>
    </b-popover>
    <b-modal
      v-model="modalShow"
      centered
      no-close-on-backdrop
      title="Comment"
      id="comment-modal"
      ref="modal"
      size="md"
    >
      <b-overlay :show="loading">
        <!-- {{ commentsRecording }} -->
        <div
          v-for="(item, index) in commentList"
          :key="index"
          class="leave-comments-box"
        >
          <!-- {{ item }} -->
          <p>
            <b>{{ item.name }}</b>
            <b>{{ item.created_at }}</b>
          </p>
          <p :class="'importance-' + item.importance">{{ item.comment }}</p>
        </div>

        <b-form-group
          label-for="comments"
          :label="$t('page.leaveComments')"
          class="required"
        >
          <b-form-textarea
            id="leaveComments"
            rows="8"
            v-model="comments"
          ></b-form-textarea>
        </b-form-group>
        <b-col md="12" class="my-1">
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label="Highlight Level"
            label-align-sm="right"
            label-size="sm"
            label-for="last_comment_level"
            class="required"
          >
            <SelectLoading
              v-if="option.last_comment_level == 0"
              :size="0.7"
            ></SelectLoading>
            <sv-select
              v-model="last_comment_level"
              :placeholder="$t('page.please_choose')"
              :options="option.last_comment_level"
              :reduce="options => options.value"
              :clearable="false"
              id="last_comment_level"
              label="label"
            >
            </sv-select>
          </b-form-group>
        </b-col>
      </b-overlay>
      <b-row> </b-row>
      <template v-slot:modal-footer="{ close }">
        <b-button
          size="sm"
          variant="primary"
          @click="submit()"
          :disabled="disabled"
          >{{ $t('page.submitText') }}</b-button
        >
        <b-button size="sm" variant="secondary" @click="close()">{{
          $t('page.close')
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
// import { required } from 'vuelidate/lib/validators';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import SelectLoading from '@/components/Loading/Index';
import apiBus from '@/common/apiBus/index';
import { mapGetters } from 'vuex';
export default {
  name: 'Comment',
  components: { SelectLoading },
  data() {
    return {
      loading: false,
      modalShow: false,
      disabled: false,
      // submitForm: {
      commentsRecording: [],
      comments: '',
      last_comment_level: 0,
      popoverShow: true,
      option: {
        last_comment_level: [
          {
            value: 0,
            label: 'N/A'
          },
          {
            value: 1,
            label: 'Very Important'
          },
          {
            value: 2,
            label: 'Important'
          }
        ]
      }
    };
  },
  // validations() {
  //   return {
  //     submitForm: {
  //       comments: {
  //         required
  //       }
  //     }
  //   };
  // },
  methods: {
    show() {
      this.disabled = true;
      this.loading = true;
      this.last_comment_level = 0;
      // apiBus.purchase
      //   .getPurchaseCommentf({
      //     purchase_target_id: this.id
      //   })
      //   .then(data => {
      //     console.log(data);
      //     this.disabled = false;
      //     this.loading = false;
      //     this.commentsRecording = data.data.data;
      //   })
      //   .catch(error => {
      //     this.disabled = false;
      //     this.loading = false;
      //     this.commentsRecording = [];
      //     console.log(error);
      //   });
      this.disabled = false;
      this.loading = false;

      this.comments = '';
      this.modalShow = true;
    },
    submit() {
      if (this.comments != '' && this.comments != null) {
        console.log('提交');

        this.disabled = true;
        this.loading = true;
        apiBus.purchase
          .savePurchaseComment({
            purchase_target_id: this.id,
            comment: this.comments,
            importance: this.last_comment_level
          })
          .then(data => {
            console.log(data);
            this.disabled = false;
            this.loading = false;
            // this.grade = this.last_comment_level;
            this.modalShow = false;
            this.initFn();
            this.$store.dispatch(PROMPT_STATUS, {
              dismissCountDown: 5, //初始提示作用于定时器
              alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
              alertText: 'Successfully!' // 提示内容
            });
          })
          .catch(error => {
            this.disabled = false;
            this.loading = false;
            this.$store.dispatch(PROMPT_STATUS, {
              dismissCountDown: 5, //初始提示作用于定时器
              alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
              alertText:
                error.data == undefined ? error.message : error.data.message // 提示内容
            });
          });
      } else {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Fields marked with * are required!' // 提示内容
        });
      }
    },
    init() {
      // this.searchOptions();
      console.log(this.grade);
    }
  },
  props: ['Index', 'grade', 'id', 'initFn', 'commentList'],
  computed: {
    ...mapGetters(['currentUser']) // 获取用户名
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped>
button.comment {
  background: transparent;
  border: 0;
}
.flex {
  display: flex;
}
</style>
