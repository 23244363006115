<template>
  <div style="display: inline-block">
    <b-button
      :size="size"
      variant="success"
      class="mr-2 confirm-delivery"
      @click="onShowModal()"
      >Delivery</b-button
    >
    <b-modal
      v-model="showModal"
      @hide="modelHide"
      centered
      no-close-on-backdrop
      scrollable
      title="Delivery"
      id="void_target"
      ref="modal"
    >
      <b-overlay :show="modalloading">
        <div class="confirm-delivery-modal">
          <b-row>
            <b-col md="12" class="my-1">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="3"
                label="Logistics"
                label-align-sm="right"
                label-size="sm"
                label-for="logistics"
                class="required"
              >
                <SelectLoading
                  v-if="options.logistics.length == 0"
                  :size="0.7"
                ></SelectLoading>
                <!-- -->
                <sv-select
                  v-model="logistics"
                  :placeholder="$t('page.please_choose')"
                  :options="options.logistics"
                  :reduce="(options) => options.value"
                  :selectable="(options) => !options.selectable"
                  label="label"
                >
                </sv-select>
              </b-form-group>
            </b-col>
            <b-col md="12" class="my-1" v-if="logistics != 'pick_up'">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="3"
                label="Tracking No."
                label-align-sm="right"
                label-size="sm"
                label-for="tracking_no"
                class="required"
              >
                <b-form-input
                  v-model="tracking_no"
                  type="text"
                  :placeholder="$t('page.please_choose_input')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12" class="my-1" v-else>
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="3"
                label="Pick up by"
                label-align-sm="right"
                label-size="sm"
                label-for="pick_up_by"
                class="required"
              >
                <b-form-input
                  v-model="pick_up_by"
                  type="text"
                  :placeholder="$t('page.please_choose_input')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12" class="my-1" v-if="0">
              <b-form-group
                label="Delivery Date"
                label-for="delivery_date"
                class="delivery_date"
                label-cols-sm="4"
                label-cols-lg="3"
              >
                <b-input-group>
                  <template v-slot:append>
                    <div
                      v-if="delivery_date != null && delivery_date != ''"
                      class="datepicker-x"
                      @click="initialTime(item, 'delivery_date')"
                    >
                      <b-icon-x font-scale="1.45"></b-icon-x>
                    </div>
                    <b-input-group-text>
                      <b-icon-calendar2-date></b-icon-calendar2-date>
                    </b-input-group-text>
                  </template>
                  <b-form-datepicker
                    v-model="delivery_date"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric'
                    }"
                    :hide-header="true"
                    locale="de"
                  ></b-form-datepicker>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="12" class="my-1">
              <b-form-group
                label="Quantity"
                label-for="quantity"
                class="search-flex quantity"
                :class="{
                  form_error: qty > maxQty
                }"
                label-cols-sm="4"
                label-cols-lg="3"
              >
                <b-input-group>
                  <template v-slot:append>
                    <b-input-group-text> / {{ maxQty }} </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="qty"
                    type="number"
                    min="0"
                    :max="maxQty"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="submit()"
          :disabled="submitDisabled"
        >
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()"> Cancel </b-button>
      </template></b-modal
    >
  </div>

  <!-- <Others></Others> -->
</template>

<script>
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import SelectLoading from '@/components/Loading/Index';
export default {
  name: 'ListConfirmDelivery',

  data() {
    return {
      submitDisabled: false,
      modalloading: false,
      showModal: false,
      logistics: null,
      tracking_no: null,
      delivery_date: null,
      pick_up_by: null,
      qty: 0,
      options: {
        logistics: []
      }
    };
  },
  props: [
    'id',
    'initDataFn',
    'size',
    'maxQty',
    'initLogistics',
    'initTrackingNo',
    'initPickUpBy'
  ],
  // inheritAttrs: false,
  components: { SelectLoading },
  methods: {
    initialTime(obj, name) {
      obj[name] = null;
    },
    onShowModal() {
      this.showModal = true;
      this.submitDisabled = false;
      this.logistics = null;

      this.tracking_no = null;
      this.pick_up_by = null;
      this.qty = this.maxQty;
      apiBus.purchase
        .getExpressList()
        .then((data) => {
          this.options.logistics = data.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit() {
      // this.delivery_date = this.format(new Date());
      var data;
      if (this.qty > this.maxQty) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'The quantity entered is out of limit.' // 提示内容
        });
        return false;
      }
      if (this.logistics == '' || this.logistics == null) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Fields marked with * are required!' // 提示内容
        });
        return false;
      } else {
        if (
          this.logistics != 'pick_up' &&
          (this.tracking_no == '' || this.tracking_no == null)
        ) {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Fields marked with * are required!' // 提示内容
          });
          return false;
        } else if (
          this.logistics == 'pick_up' &&
          (this.pick_up == '' || this.pick_up == null)
        ) {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Fields marked with * are required!' // 提示内容
          });
          return false;
        }
      }
      data = {
        purchase_overview_id: this.id,
        logistics: this.logistics,
        delivery_date: this.delivery_date,
        delivery_qty: this.qty
      };
      if (data.delivery_date == '' || data.delivery_date == null)
        data.delivery_date = this.format(new Date());
      if (this.logistics == 'pick_up') {
        data.pick_up_by = this.pick_up_by;
      } else {
        data.tracking_no = this.tracking_no;
      }
      this.modalloading = true;
      this.submitDisabled = true;
      apiBus.purchase
        .purchaseOverviewDelivery(data)
        .then((data) => {
          console.log(data);
          this.modalloading = false;
          this.submitDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
          this.showModal = false;
          this.initDataFn();
        })
        .catch((error) => {
          console.log(error);
          this.modalloading = false;
          this.submitDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    modelHide() {
      if (document.getElementsByClassName('form_error').length > 0) {
        var scroll_top = 0;
        if (document.documentElement && document.documentElement.scrollTop) {
          scroll_top = document.documentElement.scrollTop;
        } else if (document.body) {
          scroll_top = document.body.scrollTop;
        }
        var divTop = document
          .getElementsByClassName('form_error')[0]
          .getBoundingClientRect().top;
        // console.log(divTop);
        window.scrollTo(
          0,
          divTop + scroll_top - document.documentElement.clientHeight / 3
        );
      }
    },
    add0(m) {
      return m < 10 ? '0' + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      return y + '-' + this.add0(m) + '-' + this.add0(d);
    },
    init() {}
  },

  computed: {},
  mounted() {
    this.init();
  }
};
</script>
<style scoped>
.confirm-delivery {
  color: #fff;
}
.confirm-delivery-modal {
  height: 300px;
}
</style>
