<template>
  <div style="display: inline-block">
    <div
      class="text-primary text-line-clamp-2 cursor-pointer"
      @click="onShowModal()"
    >
      {{ note ? note : $t('page.please_choose_input') }}
    </div>
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Edit"
      id="edit-invoice-note-information"
      ref="modal"
      size="md"
    >
      <b-overlay :show="modalloading">
        <b-row>
          <b-col md="12" class="my-1">
            <b-form-group
              class="remark"
              label-for="remark"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Note"
            >
              <b-form-textarea
                v-model.trim="remark"
                :disabled="allDisabled"
                rows="3"
                :placeholder="$t('page.please_choose_input')"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="submit()"
          :disabled="submitDisabled"
        >
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()"> Cancel </b-button>
      </template></b-modal
    >
  </div>
</template>
  
  <script>
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
export default {
  name: 'EditInvoiceNoteInformation',

  data() {
    return {
      submitDisabled: false,
      modalloading: false,
      showModal: false,
      remark: null
    };
  },
  props: ['id', 'initFn', 'disabled', 'allDisabled', 'note'],
  methods: {
    initialTime(obj, name) {
      console.log(obj, name);
      obj[name] = null;
    },
    onShowModal() {
      this.showModal = true;
      this.remark = this.note;
    },
    submit() {
      this.submitDisabled = true;
      this.modalloading = true;
      apiBus.purchaseInvoice
        .updatePurchaseInvoiceNote({
        purchase_over_view_id: this.id,
          note: this.remark,
        })
        .then((data) => {
          console.log(data);
          this.submitDisabled = false;
          this.modalloading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
          this.initFn();
          this.showModal = false;
        })
        .catch((error) => {
          console.log(error);
          this.submitDisabled = false;
          this.modalloading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: error.data.message // 提示内容
          });
        });
    },
    init() {}
  }
};
</script>
<style scoped>
.text-line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.cursor-pointer {
  cursor: pointer;
  color: #00a0e9;
}
</style>