<template>
  <div v-if="tableData">
    <b-row class="table-link-select">
      <b-col md="1">
        <b-form-select
          v-if="limit"
          v-model="limit"
          id="perPageSelect"
          size="sm"
          :options="tableData.pageOptions"
        ></b-form-select>
      </b-col>
      records per page
    </b-row>
    <!-- {{ options.supplier_list }} -->
    <div class="table-auto">
      <!-- sticky-header -->
      <b-table
        responsive
        striped
        hover
        v-if="tableData && limit"
        ref="selectableTable"
        :id="tableData.tabId"
        :class="tableData.class"
        :fields="tableData.fields"
        :items="itemsFn"
        :current-page="tableObj.page"
        :per-page="limit"
        :tbody-tr-class="tableData.rowClass"
        :sort-desc="
          tableObj.sort
            ? tableObj.sort[Object.keys(tableObj.sort)[0]] == 'asc'
              ? false
              : true
            : null
        "
        :sort-by="tableObj.sort ? Object.keys(tableObj.sort)[0] : null"
      >
        <template #head(select)="data">
          <div style="display: flex">
            <b-form-checkbox
              v-if="data.field.allSelect != null"
              v-model="data.field.allSelect"
              @change="checkboxAll($event)"
            ></b-form-checkbox>
            <!-- {{ data.field.label }} -->
          </div>
        </template>
        <template #head(to_warehouse_deadline)="">
          <p style="display: flex">
            Purchase<br />
            Information
          </p>
        </template>
        <template #head(purchase_information)="data">
          <p
            style="display: flex"
            v-if="data.field.html"
            v-html="data.field.html"
          ></p>
          <p v-else>{{ data.label }}</p>
        </template>
        <template #head(qty)="data">
          <p
            style="display: flex"
            v-if="data.field.html"
            v-html="data.field.html"
          ></p>
          <p v-else>{{ data.label }}</p>
        </template>
        <template #head(refundable)="data">
          <p
            style="display: flex"
            v-if="data.field.html"
            v-html="data.field.html"
          ></p>
          <p v-else>{{ data.label }}</p>
        </template>

        <template #head(action)="data">
          <p class="th-action">{{ data.label }}</p>
        </template>
        <template v-slot:cell(select)="row">
          <!-- <template v-if="row.item.select != 'disabled'"> -->
          <div title="">
            <!-- size="lg" -->
            <b-form-checkbox
              @input="checkboxChange($event, row.index, row.item)"
              v-model="row.item.select"
              :disabled="row.item.selectDisabled == 'disabled'"
            ></b-form-checkbox>
          </div>
        </template>

        <template v-slot:cell(status)="row">
          <div
            v-if="row.item.status != null"
            :id="row.item.status == 'Delivered' ? `status-${row.index}` : null"
            :class="['color_' + row.item.status.replace(/ /g, '_')]"
          >
            {{ row.item.status }}
          </div>
          <b-popover
            v-if="row.item.status == 'Delivered' || 0"
            :target="`status-${row.index}`"
            triggers="hover"
            placement="left"
            show.sync="popoverShow"
          >
            <template>
              <p v-if="row.item.logistics">{{ row.item.logistics }}</p>
              <p v-if="row.item.logistics != 'Pick up' && row.item.tracking_no">
                {{ row.item.tracking_no }}
              </p>
              <p v-if="row.item.logistics == 'Pick up' && row.item.pick_up_by">
                Pick up by {{ row.item.pick_up_by }}
              </p>
            </template>
          </b-popover>
        </template>

        <template v-slot:cell(order_information)="row">
          <a :href="row.item.order_information.cps_order_url" target="_blank">{{
            row.item.order_information.cps_order_number
          }}</a>
          <p v-if="row.item.order_information.order_date">
            {{ row.item.order_information.order_date }}
          </p>
          <p>{{ row.item.order_information.position }}</p>
          <!-- {{ row.item.order_date ? 'YES' : 'NO' }} -->
        </template>
        <template v-slot:cell(purchase_limit)="row">
          {{ row.item.purchase_limit ? 'YES' : 'NO' }}
        </template>

        <template v-slot:cell(related_information)="row">
          <p v-for="(item, index) in row.item.related_information" :key="index">
            {{ item }}
          </p>
        </template>

        <template v-slot:cell(order_priority)="row">
          <template v-if="row.item.order_priority != null">
            <!-- {{ row.item }} -->
            <div :class="order_priority_obj[row.item.order_priority].class">
              {{ order_priority_obj[row.item.order_priority].label }}
            </div>
          </template>
        </template>

        <template v-slot:cell(invoice_status)="row">
          <div :class="`text_${row.item.invoice_status}`">
            {{ row.item.invoice_status }}
          </div>
        </template>

        <template v-slot:cell(channel)="row">
          <div class="table-w-150">{{ row.item.channel }}</div>
        </template>

        <template v-slot:cell(note)="row">
          <div class="table-w-150" v-if="tableData.tabId == 'purchase_invoice'">
            <EditInvoiceNoteInformation
              :id="row.item.id"
              :note="row.item.note"
              :initFn="initDataFn"
            ></EditInvoiceNoteInformation>
          </div>
        </template>


        <template v-slot:cell(mb_hk_invoice_number)="row">
          <div class="table-w-150" v-if="tableData.tabId == 'purchase_invoice'">
            <EditInvoiceMBHKInvoiceNumberInformation
              v-if="row.item.invoice_status !== 'Pending'"
              :id="row.item.id"
              :number="row.item.mb_hk_invoice_number"
              :initFn="initDataFn"
            ></EditInvoiceMBHKInvoiceNumberInformation>
          </div>
        </template>

        <template v-slot:cell(image)="row">
          <div class="relativea">
            <el-image
              v-if="
                row.item.image && tableData.items && tableData.items.length > 0
              "
              style="width: 100px; height: 100px"
              class="pointer"
              :src="row.item.image"
              @click="showImg(row.item.image)"
            >
              <div slot="placeholder" v-loading="true" class="image-slot">
                <img
                  alt="edit"
                  width="100"
                  src="@/assets/media/users/default.png"
                />
              </div>
              <div slot="error" class="image-slot">
                <img
                  alt="edit"
                  width="100"
                  src="@/assets/media/users/default.png"
                />
              </div>
            </el-image>
            <img
              v-else
              alt="edit"
              width="100"
              src="@/assets/media/users/default.png"
            />
          </div>
        </template>

        <template v-slot:cell(article_information)="row">
          <!-- {{ row.item.article_information }} -->
          <div>
            <p>{{ row.item.article_information.brand }}</p>
            <p>{{ row.item.article_information.brand_article_number }}</p>
            <p>{{ row.item.article_information.product_name }}</p>
            <p v-if="row.item.article_information.product_name_cn">
              {{ row.item.article_information.product_name_cn }}
            </p>
            <div style="display: flex">
              <div class="color_box mr-5" style="display: flex">
                Color:
                <!-- {{ row.item.article_information.color.indexOf('#') }} -->
                <div v-if="tableData.tabId == 'saas_purchase_invoice'">
                  {{ row.item.article_information.color_name }}
                </div>
                <div
                  v-else-if="
                    row.item.article_information.color.indexOf('#') >= 0
                  "
                  v-b-popover.hover.topleft="
                    row.item.article_information.color_name
                  "
                  :style="`background-color:${row.item.article_information.color};width: 20px;height: 20px;margin-left: 5px;background-size: 100%;`"
                ></div>
                <div
                  v-else
                  v-b-popover.hover.topleft="
                    row.item.article_information.color_name
                  "
                  :style="`background:url(${row.item.article_information.color});width: 20px;height: 20px;margin-left: 5px;background-size: 100%;`"
                ></div>
              </div>
              <div class="size_box" style="display: flex; white-space: nowrap">
                <p>Size: {{ row.item.article_information.size }}</p>
              </div>
            </div>
            <p>{{ row.item.article_information.pos_article_no }}</p>
          </div>
        </template>

        <template v-slot:cell(progress)="row">
          <MulticolorProgressBar
            class="mb-2"
            style="width: 100px"
            :progressNumber="[
              {
                value: row.item.progress.stored_qty,
                variant: 'success'
              },
              {
                value: row.item.progress.purchasing_qty,
                variant: 'primary'
              },
              {
                value: row.item.progress.not_purchased_qty,
                variant: 'secondary'
              }
            ]"
            :progressH="'1.5rem'"
            :progressMax="
              row.item.progress.not_purchased_qty +
              row.item.progress.stored_qty +
              row.item.progress.purchasing_qty
            "
            :purchaseQty="row.item.qty"
          ></MulticolorProgressBar>
          <p>To be purchased: {{ row.item.progress.not_purchased_qty }}</p>
          <p>Confirmed: {{ row.item.progress.purchasing_qty }}</p>
          <p>Stored: {{ row.item.progress.stored_qty }}</p>
        </template>

        <template v-slot:cell(purchase_information)="row">
          <div>
            <p>{{ row.item.purchase_information.purchase_id }}</p>
            <p>{{ row.item.purchase_information.purchase_date }}</p>
            <p>{{ row.item.purchase_information.reserve_no }}</p>
          </div>
        </template>

        <template v-slot:cell(sales_channel)="row">
          <p v-if="options.sales_channel">
            <!-- {{ options.sales_channel }} -->
            {{ outputLabel(options.sales_channel, row.item.sales_channel) }}
          </p>
        </template>

        <template v-slot:cell(reserved_qty)="row">
          <TableSimple
            v-if="
              row.item.reserved_qty != 0 &&
              (tableData.tabId == 'purchase_summary_detail' ||
                tableData.tabId == 'purchase_summary')
            "
            :Text="row.item.reserved_qty"
            :Fields="table_simple_fields"
            :TableId="'reserved_qty'"
            :InitItems="initItems"
            :ProductId="row.item.product_id"
            QtyType="Reserved"
          ></TableSimple>
          <p v-else>
            {{ row.item.reserved_qty }}
          </p>
        </template>

        <template v-slot:cell(confirmed_qty)="row">
          <TableSimple
            v-if="
              row.item.confirmed_qty != 0 &&
              (tableData.tabId == 'purchase_summary_detail' ||
                tableData.tabId == 'purchase_summary')
            "
            :Text="row.item.confirmed_qty"
            :Fields="table_simple_fields"
            :TableId="'confirmed_qty'"
            :InitItems="initItems"
            :ProductId="row.item.product_id"
            QtyType="Confirmed"
          ></TableSimple>
          <p v-else>
            {{ row.item.confirmed_qty }}
          </p>
        </template>

        <template v-slot:cell(delivered_qty)="row">
          <TableSimple
            v-if="
              row.item.delivered_qty != 0 &&
              (tableData.tabId == 'purchase_summary_detail' ||
                tableData.tabId == 'purchase_summary')
            "
            :Text="row.item.delivered_qty"
            :Fields="table_simple_fields"
            :TableId="'delivered_qty'"
            :InitItems="initItems"
            :ProductId="row.item.product_id"
            QtyType="Delivered"
          ></TableSimple>
          <p v-else>
            {{ row.item.delivered_qty }}
          </p>
        </template>

        <template v-slot:cell(stored_qty)="row">
          <TableSimple
            v-if="
              row.item.stored_qty != 0 &&
              (tableData.tabId == 'purchase_summary_detail' ||
                tableData.tabId == 'purchase_summary')
            "
            :Text="row.item.stored_qty"
            :Fields="table_simple_fields"
            :TableId="'stored_qty'"
            :InitItems="initItems"
            :ProductId="row.item.product_id"
            QtyType="Stored"
          ></TableSimple>
          <p v-else>
            {{ row.item.stored_qty }}
          </p>
        </template>

        <template v-slot:cell(total_inventory)="row">
          <TableSimple
            v-if="row.item.total_inventory != 0"
            :Text="row.item.total_inventory"
            :Fields="table_simple_fields2"
            :TableId="'total_inventory'"
            :InitItems="initItems2"
            :ProductId="row.item.product_id"
            QtyType="1"
          ></TableSimple>
          <p v-else>
            {{ row.item.total_inventory }}
          </p>
        </template>

        <template v-slot:cell(available_inventory)="row">
          <!-- <TableSimple
            v-if="row.item.available_inventory != 0"
            :Text="row.item.available_inventory"
            :Fields="table_simple_fields2"
            :TableId="'available_inventory'"
            :InitItems="initItems2"
            :ProductId="row.item.product_id"
            QtyType="2"
          ></TableSimple> -->
          <p>
            {{ row.item.available_inventory }}
          </p>
        </template>

        <template v-slot:cell(saleable_qty)="row">
          <p v-if="row.item.saleable_qty < 0">
            <span style="color: #f74f6a"> {{ row.item.saleable_qty }}</span>
            （库存不足支持已售订单，需增加入库量）
          </p>
          <p v-else>
            {{ row.item.saleable_qty }}
          </p>
        </template>

        <template v-slot:cell(invoice_no)="row">
          <p
            v-for="(data, index) in row.item.invoice_no"
            :key="index"
            style="
              max-width: 250px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            "
          >
            {{ data }}
          </p>
        </template>

        <template v-slot:cell(invoice_date)="row">
          <p
            v-for="(data, index) in row.item.invoice_date"
            :key="index"
            style="
              max-width: 250px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            "
          >
            {{ data }}
          </p>
        </template>

        <template v-slot:cell(refundable)="row">
          <p
            v-for="(data, index) in row.item.refundable"
            :key="index"
            style="
              max-width: 250px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            "
          >
            {{ data }}
          </p>
        </template>

        <template v-slot:cell(order_id_arr)="row">
          <p v-for="(data, index) in row.item.order_id_arr" :key="index">
            {{ data }}
          </p>
        </template>

        <template v-slot:cell(purchase_id_arr)="row">
          <p v-for="(data, index) in row.item.purchase_id_arr" :key="index">
            {{ data }}
          </p>
        </template>

        <template v-slot:cell(payment_status)="row">
          <div v-if="row.item.payment_date != ''" class="text_Paid">Paid</div>
          <div v-else class="text_UnPaid">UnPaid</div>
        </template>

        <template v-slot:cell(action)="row">
          <!-- {{ tableData }} -->
          <div
            style="display: flex; align-items: center"
            v-if="tableData.tabId == 'purchase_target'"
          >
            <block v-if="row.item.status != 'On Hold'">
              <Comments
                :Index="row.index"
                :grade.sync="row.item.grade"
                :id="row.item.id"
                :commentList="row.item.comment_list"
                :initFn="initDataFn"
              ></Comments>

              <QuickReserve
                class="mr-2"
                :id="row.item.id"
                :supplierName="row.item.supplier_name"
              ></QuickReserve>
            </block>

            <router-link
              :to="{
                name: 'purchase-target-detail-index',
                params: {
                  purchaseId: row.item.id
                }
              }"
              class="btn btn-primary btn-sm mr-2"
              >View</router-link
            >
            <block v-if="row.item.status != 'On Hold'">
              <CreatePurchase
                v-if="
                  row.item.progress.not_purchased_qty > 0 &&
                  row.item.status != 'Voided'
                "
                :purchaseTargetId="row.item.id"
                :maxQty="row.item.progress.not_purchased_qty"
                :disabled="row.item.qty <= 0"
                :initFn="initDataFn"
                :order-number="row.item.article_information.pos_article_no"
                :brand="row.item.article_information.brand"
                :size="'sm'"
                class="mr-2 button"
              ></CreatePurchase>
              <Article
                style="margin-right: 5px; display: flex; align-items: center"
                :purchaseTargetId="row.item.id"
                :productId="row.item.product_id"
                v-if="0"
              ></Article>
            </block>
          </div>
          <div
            style="display: flex; align-items: center"
            v-else-if="tableData.tabId == 'supplier_management'"
          >
            <router-link
              :to="{
                name: 'supplier-management-detail-index',
                params: {
                  purchaseId: '1'
                }
              }"
              class="btn btn-primary btn-sm"
              >View</router-link
            >
          </div>
          <div
            style="display: flex; align-items: center"
            v-else-if="tableData.tabId == 'purchase_overview'"
          >
            <router-link
              :to="{
                name: 'purchase-overview-detail-index',
                params: {
                  purchaseId: row.item.id
                }
              }"
              class="btn btn-primary btn-sm mr-2"
              >View</router-link
            >
            <!-- row.item.order_information.cps_order_number.slice(0, 2) != '14' -->
            <RelatedPurchase
              v-if="0"
              :id="row.item.id"
              :initFn="initDataFn"
            ></RelatedPurchase>
            <ConfirmPurchase
              v-if="row.item.status == 'Reserved'"
              :id="row.item.id"
              :maxQty="row.item.qty"
              :initDataFn="initDataFn"
              :size="'sm'"
            ></ConfirmPurchase>
            <ConfirmDelivery
              v-if="
                row.item.status == 'Confirmed' ||
                row.item.status == 'Partial Delivered'
              "
              :id="row.item.id"
              :maxQty="row.item.qty - row.item.delivery_qty"
              :initDataFn="initDataFn"
              :initLogistics="row.item.logistics"
              :initTrackingNo="row.item.tracking_no"
              :initPickUpBy="row.item.pick_up_by"
              :size="'sm'"
            >
            </ConfirmDelivery>
          </div>

          <div
            style="display: flex; align-items: center"
            v-else-if="tableData.tabId == 'warehouse_entrance'"
          >
            <router-link
              :to="{
                name: 'warehouse-entrance-detail-index',
                params: {
                  warehouse_entrance_id: row.item.id
                }
              }"
              class="btn btn-primary btn-sm mr-2"
              >View</router-link
            >
            <!-- row.item.status == 'Incoming' -->
            <DeliveryReceived
              v-if="0"
              class="mr-2"
              :warehouseEntranceId="row.item.id"
              :initFn="initDataFn"
            ></DeliveryReceived>
          </div>

          <div
            style="display: flex; align-items: center"
            v-else-if="tableData.tabId == 'article_review'"
          >
            <router-link
              :to="{
                name: 'article-review-detail-index',
                params: {
                  article_review_id: row.item.id
                }
              }"
              class="btn btn-primary btn-sm mr-2"
              >View</router-link
            >
          </div>

          <div v-else-if="tableData.tabId == 'purchase_summary'">
            <router-link
              :to="{
                name: 'purchase-summary-detail-index',
                params: {
                  purchaseSummaryId: row.item.product_id,
                  orderDateFrom: tableObj.order_date_from,
                  orderDateTo: tableObj.order_date_to,
                  salesChannel: tableObj.sales_channel
                }
              }"
              class="btn btn-primary btn-sm mr-2"
              >View</router-link
            >
          </div>

          <div v-else-if="tableData.tabId == 'purchase_summary_detail'">
            <router-link
              :to="{
                name: 'purchase-target-detail-index',
                params: {
                  purchaseId: row.item.id
                }
              }"
              class="btn btn-primary btn-sm mr-2"
              >View</router-link
            >
          </div>

          <div
            style="display: flex; align-items: center"
            v-else-if="tableData.tabId == 'purchase_invoice'"
          >
            <EditInvoiceInformation
              v-b-popover.hover.topleft="
                row.item.invoice_status != 'Processing' &&
                row.item.invoice_status != 'Completed'
                  ? 'Waiting For upload invoice'
                  : ''
              "
              :allDisabled="row.item.invoice_status == 'Completed'"
              :id="row.item.id"
              :purchase_invoice_id="row.item.purchase_invoice_id"
              :disabled="
                row.item.invoice_status != 'Processing' &&
                row.item.invoice_status != 'Completed'
              "
              :qty="row.item.qty"
              :initFn="initDataFn"
            ></EditInvoiceInformation>

            <HistoryInvoiceInformation
              v-if="0"
              :id="row.item.purchase_invoice_id"
            ></HistoryInvoiceInformation>
          </div>

          <div
            style="display: flex; align-items: center"
            v-else-if="tableData.tabId == 'saas_purchase_invoice'"
          >
            <EditSaasInvoiceInformation
              v-b-popover.hover.topleft="
                row.item.invoice_status != 'Processing' &&
                row.item.invoice_status != 'Completed'
                  ? 'Waiting For upload invoice'
                  : ''
              "
              :allDisabled="row.item.invoice_status == 'Completed'"
              :id="row.item.id"
              :purchase_invoice_id="row.item.purchase_invoice_id"
              :disabled="
                row.item.invoice_status != 'Processing' &&
                row.item.invoice_status != 'Completed'
              "
              :qty="row.item.qty"
              :initFn="initDataFn"
            ></EditSaasInvoiceInformation>

            <HistoryInvoiceInformation
              v-if="0"
              :id="row.item.purchase_invoice_id"
            ></HistoryInvoiceInformation>
          </div>

          <div
            style="display: flex; align-items: center; white-space: nowrap"
            v-else-if="tableData.tabId == 'upload_invoice'"
          >
            <UploadManagement
              :id="row.item.id"
              :initFn="initDataFn"
              :status="row.item.status_id"
            ></UploadManagement>
            <router-link
              v-if="0"
              :to="{
                name: 'upload-invoice-detail',
                params: {
                  id: row.item.id
                }
              }"
              class="btn btn-primary btn-sm mr-2"
              >View</router-link
            >
            <b-button variant="primary" @click="row.item.openFn(row.item.id)"
              >View PDF
            </b-button>
            <ConfirmPayment
              v-if="row.item.payment_date == ''"
              :id="row.item.id"
              :initFn="initDataFn"
              :status="row.item.status_id"
              :payment_date="row.item.payment_date"
              :invoice_total_amount="row.item.invoice_total_amount"
            ></ConfirmPayment>
            <!-- :disabled="disabled" -->
          </div>

          <div
            style="display: flex; align-items: center"
            v-else-if="tableData.tabId == 'saas_purchase_invoice_file'"
          >
            <SaaSUploadManagement
              :id="row.item.id"
              :initFn="initDataFn"
              :status="row.item.status_id"
            ></SaaSUploadManagement>
            <router-link
              v-if="0"
              :to="{
                name: 'saas-purchase-invoice-file-detail',
                params: {
                  id: row.item.id
                }
              }"
              class="btn btn-primary btn-sm mr-2"
              >View</router-link
            >
            <b-button variant="primary" @click="row.item.openFn(row.item.id)"
              >View PDF
            </b-button>
            <!-- :disabled="disabled" -->
          </div>
        </template>
      </b-table>
      <div class="table_prompt" v-if="tableData.totalItems == 0">No Data</div>
      <div class="table_prompt_2" v-if="tableData.status == 'error'">
        Sorry, an error occurred in the system !
      </div>
    </div>
    <!-- 分页 -->
    <b-pagination
      v-if="limit"
      v-model="tableObj.page"
      :total-rows="tableData.totalItems"
      :per-page="limit"
      align="right"
      size="md"
    >
      <template #first-text><span>First</span></template>
      <template #prev-text><span>« Previous</span></template>
      <template #next-text><span>Next »</span></template>
      <template #last-text><span>Last</span></template>
    </b-pagination>
    <p class="paginated-text">
      Showing {{ (tableObj.page - 1) * limit + 1 }} to
      {{
        Math.ceil(
          tableObj.page * limit > tableData.totalItems
            ? tableData.totalItems
            : tableObj.page * limit
        )
      }}
      of {{ tableData.totalItems }} entries
    </p>
    <b-modal
      v-model="imageModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Image"
      hide-footer
      id="image-box"
      ref="modal"
      size="lg"
    >
      <img :src="showImgUrl" alt="" />
    </b-modal>
  </div>
</template>
<script>
import apiBus from '@/common/apiBus/index';
import MulticolorProgressBar from '@/components/MulticolorProgressBar/Index.vue';
import Article from '@/views/content/Article/Index.vue';
import QuickReserve from '@/views/content/QuickReserve/Index.vue';
import CreatePurchase from '@/views/content/CreatePurchase/Index';
import Comments from '@/components/Comments/Index.vue';
import TableSimple from '@/components/Modal/TableSimple.vue';
import ConfirmPurchase from '@/views/content/Detail/PurchaseoOverview/Information/ConfirmPurchase.vue';
import RelatedPurchase from '@/views/content/Detail/PurchaseoOverview/Information/RelatedPurchase.vue';
import ConfirmDelivery from '@/views/content/ConfirmDelivery/Index.vue';
import DeliveryReceived from '@/views/content/DeliveryReceived/Index.vue';
import EditInvoiceInformation from '@/views/content/EditInvoiceInformation/Index.vue';
import EditInvoiceNoteInformation from '@/views/content/EditInvoiceInformation/Note.vue';
import EditInvoiceMBHKInvoiceNumberInformation from '@/views/content/EditInvoiceInformation/MBHKInvoiceNumber.vue';
import EditSaasInvoiceInformation from '@/views/content/EditSaasInvoiceInformation/Index.vue';
import HistoryInvoiceInformation from '@/views/content/History/InvoiceInformation.vue';
import UploadManagement from '@/views/content/UploadManagement/Index.vue';
import SaaSUploadManagement from '@/views/content/SaaSUploadManagement/Index.vue';
import ConfirmPayment from '@/views/content/ConfirmPayment/Index.vue';

export default {
  name: 'CustomTable',
  components: {
    MulticolorProgressBar,
    Article,
    CreatePurchase,
    QuickReserve,
    Comments,
    TableSimple,
    ConfirmPurchase,
    RelatedPurchase,
    ConfirmDelivery,
    DeliveryReceived,
    EditInvoiceInformation,
    EditInvoiceNoteInformation,
    EditInvoiceMBHKInvoiceNumberInformation,
    EditSaasInvoiceInformation,
    HistoryInvoiceInformation,
    UploadManagement,
    SaaSUploadManagement,
    ConfirmPayment
  },
  data() {
    return {
      imageModal: false,
      showImgUrl: '',
      popoverShow: true,
      sortDesc: true,
      limit: null,
      table_simple_fields: [
        // {
        //   key: 'product_id',
        //   label: 'ID'
        // },
        {
          key: 'supplier',
          label: 'Supplier Name'
        },
        {
          key: 'qty',
          label: 'Quantity'
        }
      ],
      table_simple_fields2: [
        {
          key: 'warehouse',
          label: 'Warehouse Name'
        },
        {
          key: 'qty',
          label: 'Quantity'
        }
      ],
      order_priority_obj: {
        1: { label: 'VIP', class: 'text_vip' },
        2: { label: 'Normal', class: 'text_normal' },
        3: { label: 'Urgent', class: 'text_urgent' }
      }
    };
  },
  props: {
    itemsFn: {
      type: Function
    },
    tableData: {
      type: Object
    },
    tableObj: {
      type: Object
    },
    checkboxAll: {
      type: Function
    },
    checkboxChange: {
      type: Function
    },
    tableId: {
      type: String
    },
    tableLoading: {
      type: Boolean
    },
    sortName: {
      type: String
    },
    options: {
      type: Object
    }
  },
  created() {},
  methods: {
    showImg(url) {
      this.showImgUrl = url;
      this.imageModal = true;
    },
    initDataFn() {
      this.$root.$emit('bv::refresh::table', this.tableId);
    },
    initItems(data) {
      return apiBus.purchase
        .purchaseSummarySupplierDetail(data)
        .then((data) => {
          let item = [];
          data.data.data.supplier_qty_list.forEach((key) => {
            item.push({
              product_id: data.data.data.product_id,
              supplier: this.outputLabel(
                this.options.supplier_list,
                key.supplier
              ),
              qty: key.qty
            });
          });
          return item;
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
    },
    initItems2(data) {
      return apiBus.warehouse
        .getInventoryWarehouseDetail(data)
        .then((data) => {
          let item = [];
          console.log(data);
          data.data.data.warehouse_qty_list.forEach((key) => {
            item.push({
              qty: key.qty,
              warehouse: key.warehouse
            });
          });
          return item;
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
    },
    outputLabel(data, val) {
      let label;
      data.forEach((item) => {
        if (item.value == val) {
          label = item.label;
        }
      });
      return label;
    },
    init() {
      // console.log('初始化');
    }
  },

  computed: {},
  mounted() {
    this.limit = JSON.parse(JSON.stringify(this.tableObj.limit));
    this.init();
  },
  watch: {
    limit: function (o, n) {
      this.tableObj.limit = o;
      if (n != null) {
        this.tableObj.page = 1;
      }
    }
  }
};
</script>
<style scoped>
td p,
td a {
  margin-bottom: 2px;
  font-size: 13px;
  color: #333;
}
td a {
  color: #00a0e9;
}
.table-link-select {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
  font-weight: 400;
}
.el-image .image-slot >>> .el-loading-mask {
  z-index: 96;
}
</style>
