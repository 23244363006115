<template>
  <div>
    <b-row
      v-if="configuration.text && configuration.type == 'datepicker_from_to'"
    >
      <b-col md="12">
        <b-form-group
          :label="configuration.text"
          :class="configuration.class"
          :id="configuration.id"
          label-for="datepicker-from"
          label-cols-sm="4"
          label-cols-lg="3"
        >
          <b-row>
            <b-col md="6">
              <b-input-group>
                <template v-slot:append>
                  <div
                    class="datepicker-x"
                    v-if="
                      datepickerObj[datepickerKye + '_from'] != null &&
                        datepickerObj[datepickerKye + '_from'] != ''
                    "
                    @click="
                      initialTime(datepickerObj, [datepickerKye + '_from'])
                    "
                  >
                    <b-icon-x font-scale="1.45"></b-icon-x>
                  </div>
                  <b-input-group-text>
                    <b-icon-calendar2-date></b-icon-calendar2-date>
                  </b-input-group-text>
                </template>
                <b-form-datepicker
                  v-model="datepickerObj[datepickerKye + '_from']"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                  :hide-header="true"
                  :date-disabled-fn="disabledForm"
                  :placeholder="configuration.from.placeholder || 'From'"
                  locale="de"
                ></b-form-datepicker>
              </b-input-group>
            </b-col>
            <b-col md="6">
              <b-input-group>
                <template v-slot:append>
                  <div
                    class="datepicker-x"
                    v-if="
                      datepickerObj[datepickerKye + '_to'] != null &&
                        datepickerObj[datepickerKye + '_to'] != ''
                    "
                    @click="initialTime(datepickerObj, [datepickerKye + '_to'])"
                  >
                    <b-icon-x font-scale="1.45"></b-icon-x>
                  </div>
                  <b-input-group-text>
                    <b-icon-calendar2-date></b-icon-calendar2-date>
                  </b-input-group-text>
                </template>
                <b-form-datepicker
                  v-model="datepickerObj[datepickerKye + '_to']"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                  :hide-header="true"
                  :date-disabled-fn="disabledTo"
                  :placeholder="configuration.to.placeholder || 'To'"
                  locale="de"
                ></b-form-datepicker>
              </b-input-group>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row
      class="datepicker-box"
      v-else-if="configuration.type == 'datepicker'"
    >
      {{ datepickerObj }}
      <template>
        <b-col md="12">
          <b-form-group
            :label="configuration.text"
            :class="configuration.class"
            :id="configuration.id"
            label-for="datepicker-from"
            class="search-flex"
            label-cols-sm="4"
            label-cols-lg="3"
          >
            <b-input-group>
              <template v-slot:append>
                <div
                  class="datepicker-x"
                  v-if="
                    datepickerObj[datepickerKye] != null &&
                      datepickerObj[datepickerKye] != ''
                  "
                >
                  <!-- @click="initialTime(datepickerObj, [datepickerKye + '_from'])" -->
                  <b-icon-x font-scale="1.45"></b-icon-x>
                </div>
                <b-input-group-text>
                  <b-icon-calendar2-date></b-icon-calendar2-date>
                </b-input-group-text>
              </template>
              <!-- :date-disabled-fn="dateStartDisabled" -->
              <b-form-datepicker
                v-model="datepickerObj[datepickerKye]"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                }"
                :hide-header="true"
                :placeholder="configuration.placeholder || 'From'"
                locale="de"
              ></b-form-datepicker>
              <!-- :date-disabled-fn="disabledForm" -->
            </b-input-group>
          </b-form-group>
        </b-col>
      </template>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Datepicker',

  data() {
    return {};
  },
  props: {
    configuration: Object,
    datepickerObj: Object,
    datepickerKye: String
  },
  components: {},
  methods: {
    //   删除
    initialTime(obj, name) {
      obj[name] = null;
    },
    // 禁止 Form
    disabledForm(ymd, date) {
      let limit = this.configuration?.limit;
      let limitStart = false;
      let limitEnd = false;
      if (typeof limit == 'object') {
        if (limit.start) {
          limitStart =
            date.getTime() <
            new Date().getTime() - limit.start * 24 * 60 * 60 * 1000;
        }
        if (limit.end) {
          limitEnd =
            date.getTime() >
            new Date().getTime() - limit.end * 24 * 60 * 60 * 1000;
        }
      }

      if (this.datepickerObj[this.datepickerKye + '_to'] || false) {
        return (
          date.getTime() >
            new Date(
              this.datepickerObj[this.datepickerKye + '_to']
            ).getTime() ||
          limitEnd ||
          limitStart
        );
      } else {
        return limitEnd || limitStart;
      }
    },
    // 禁止 To
    disabledTo(ymd, date) {
      let limit = this.configuration?.limit;
      let limitStart = false;
      let limitEnd = false;
      if (typeof limit == 'object') {
        if (limit.start) {
          limitStart =
            date.getTime() <
            new Date().getTime() - limit.start * 24 * 60 * 60 * 1000;
        }
        if (limit.end) {
          limitEnd =
            date.getTime() >
            new Date().getTime() - limit.end * 24 * 60 * 60 * 1000;
        }
      }
      if (this.datepickerObj[this.datepickerKye + '_from'] || false) {
        return (
          date.getTime() <
            new Date(
              this.datepickerObj[this.datepickerKye + '_from']
            ).getTime() -
              24 * 60 * 60 * 1000 ||
          limitEnd ||
          limitStart
        );
      } else {
        return limitEnd || limitStart;
      }
    }
  },

  computed: {},
  mounted() {}
};
</script>
<style scoped></style>
